// CONTACT

.contact-box {
  box-shadow: 0 0 16px 1px rgba(41, 41, 41, 0.08);
  .form-control:focus {
    box-shadow: none;
    background-color: lighten($light, 1%);
    border-color: $light $light $custom;
  }
  .form-control {
    padding: 12px 16px;
    font-size: 14px;
    border-radius: 5px;
    border-color: darken($light, 3%);
    background-color: transparent;
  }
  .form-group {
    margin-bottom: 22px;
  }
  .contact-icon {
    border: 1px solid darken($muted, 6%);
    width: 42px;
    height: 42px;
    border-radius: 50px;
    line-height: 48px;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .follow-icon {
    li {
      margin: 0 3px !important;
      a {
        background-color: transparent;
        height: 38px;
        width: 38px;
        display: block;
        line-height: 38px;
        text-align: center;
        border-radius: 50px;
        transition: all 0.5s;
        &:hover {
          background-color: $custom;
          color: $white !important;
          height: 38px;
          width: 38px;
          position: relative;
          display: block;
          line-height: 38px;
          text-align: center;
          border-radius: 50px;
          box-shadow: 0 0 0 6px rgba(255, 135, 0, 0.15);
          transition: all 0.5s;
        }
      }
    }
  }
}


.contact-loader {
  display: none;
}


#success_page {
  background-color: rgba($success, 0.25);
  padding: 10px 20px;
  border-radius: 4px;
}

.app-contact-desc p {
  overflow: hidden;
}

.error-msg{
  padding: 7px;
}

.custom-form{
  .btn-custom{
    &:focus,
    &:active{
      background-color: #ff8700;
    }
  }
}