// PRICING
.pricing-box {
  border: 1px solid $light;
  transition: all 0.5s;
  sup {
    top: -10px;
    font-size: 18px;
  }
  .price {
    border-bottom: 1px solid $light;
    transition: all 0.5s;
    h1 {
      line-height: 0.8;
    }
    h5 {
      transition: all 0.5s;
    }
    &:after {
      content: "";
      background-color: #f8f9fa;
      height: 20px;
      width: 20px;
      position: absolute;
      bottom: -10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 0;
      right: 0;
      margin: 0 auto;
      border-right: 1px solid $light;
      border-bottom: 1px solid $light;
      transition: all 0.5s;
    }
  }
  .pricing-list {
    li {
      margin: 20px 0px;
    }
  }
  &:hover,
  &.active {
    transform: scale(1.03);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    .price {
      background-color: $primary !important;
      transition: all 0.5s;
      border-color: $primary;
      &:after {
        background-color: $primary !important;
        transition: all 0.5s;
        border-color: $primary;
      }
      h2,
      h5 {
        color: $white !important;
        transition: all 0.5s;
      }
    }
  }
}
